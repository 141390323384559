const menus = [

    {
        id: 1,
        name: 'Accueil',
        links: '/',
    },
    {
        id: 2,
        name: 'Qui sommes-nous',
        links: '#nous',
    },
    {
        id: 3,
        name: 'Thème',
        links: '#theme',
    },
    /*
    {
        id: 4,
        name: 'Horaires',
        links: '#horaires',
    },
*/
    {
        id: 5,
        name: 'Char',
        links: '#char',
    },

    {
        id: 6,
        name: 'Comité',
        links: '#comite',
    },
    {
        id: 7,
        name: 'FAQ',
        links: '#faq',
    },
    {
        id: 8,
        name: 'Contact',
        links: '#contact',
    },
    
]

export default menus;